:root {
    --mainColor--: #9933FF;
    --secondColor--: #19191b;
    --thirdColor--: #000000;
    --textColor--: #ffffff;
    --hoverLink--: #916bb8;
}


.item {
    min-width: 18rem;
    /* height: 400px; */
    background-color: var(--textColor--);
    /* border: 2px solid var(--mainColor--); */
    border-radius: 0.25rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    padding: 2rem;
    box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
}

.item:hover {
    transform: scale(0.9);
    /* margin: 0 2px; */
    transition: 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.item .item-image {
    width: 100%;
    height: 16rem;
    /* background-color: var(--hoverLink--); */
      
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.25rem;
    transition: 0.4s ease-in;
}
.item-title-pricing {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    align-items: center;

}
.item .product-image {
    width: 100%;
    height: 100%;
    border-radius: 0.25rem;
    /* height: 200px; */
}
.item .item-btn-details {
    width: 100%;
    border: 1px solid transparent;
    outline: none;
    background-color: var(--mainColor--);
    color: var(--textColor--);
    font-weight: bold;
    font-size: 12px;
    padding: 8px;
    border-radius: 0.25rem;
}
.item:hover {
    background-color: var(--textColor--);
    /* border: 2px solid var(--mainColor--); */
    /* transition: 0.2s ; */

}
.item-title {
    font-size: 14px;
    /* height: 40px; */
    margin: 0;
    text-wrap: wrap;
    font-weight: bold;
    color: var(--secondColor--);
}
.item-title p {
    margin: 0;
}
.item-price  {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.item-price p {
    font-size: 14px;
    margin: 0;
}
.price-after-discount {
    font-weight: bold;
    color: var(--mainColor--);
}
.price-before-discount {
    color: var(--hoverLink--);
    text-decoration: line-through;
}

.item-btn {
    width: 100%;
}