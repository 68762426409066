:root {
    --mainColor--: #9933FF;
    --secondColor--: #19191b;
    --thirdColor--: #000000;
    --textColor--: #ffffff;
    --hoverLink--: #916bb8;
}

.box-purble {
    /* width: 24em; */
    background-color: var(--mainColor--);
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
    justify-content: space-between;
    row-gap: 4px;
    padding: 4rem;
    align-items: center;
    border-radius: 0.5rem;
    
    
}
.box-white {
    
    background-color: #8e1effb3;
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
    justify-content: space-between;
    row-gap: 4px;
    padding: 4rem;
    align-items: center;
    border-radius: 0.5rem;
    
    
}

.box-purble:hover .quick-link-box-icon {
    transform: rotate(180deg);
    transition: 0.5s 0s ease-out;
}
.box-white:hover .quick-link-box-icon {
    transform: rotate(180deg);
    transition: 0.5s 0s ease-out;
}
.box-purble .quick-link-box-heading {
    font-weight: bold;
    font-size: 4rem;
    color: var(--textColor--);
    
}
.box-white .quick-link-box-heading {
    font-weight: bold;
    font-size: 4rem;
    color: var(--textColor--);
}
.quick-link-box-desc {
    font-weight: 500;
    font-size: 1.5rem;
    color: var(--secondColor----);
}
.quick-link-box-icon {
   width: 4rem;
   height: 4rem;
}

a {
    color: var(--secondColor--);
    text-decoration: none;
}
a:hover {
    color: var(--secondColor--);
    text-decoration: none;
}


@media only screen and (min-width: 320px) and (max-width: 480px) {
    
    .box-purble {
        /* width: 24em; */
        background-color: var(--mainColor--);
        display: flex;
        flex-direction: column-reverse;
        /* flex-wrap: wrap; */
        justify-content: space-between;
        row-gap: 2rem;
        padding: 1rem;
        align-items: center;
        border-radius: 0.5rem;
        
    }

    .box-white {
    
        background-color: #8e1effb3;
        display: flex;
        flex-direction: column-reverse;
        /* flex-wrap: wrap; */
        justify-content: space-between;
        row-gap: 2rem;
        padding: 1rem;
        align-items: center;
        border-radius: 0.5rem;
        
    }

    .box-purble .quick-link-box-heading {
        font-weight: bold;
        font-size: 2rem;
        color: var(--textColor--);
        
    }
    .box-white .quick-link-box-heading {
        font-weight: bold;
        font-size: 2rem;
        color: var(--textColor--);
    }
}