:root {
    --mainColor--: #9933FF;
    --secondColor--: #19191b;
    --thirdColor--: #000000;
    --textColor--: #ffffff;
    --hoverLink--: #916bb8;
}


.image-cover {
    width: 100%;
    border-radius: 0.25rem;
    background-color: var(--textColor--);
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.25rempx solid var(--mainColor--);
    transition: 0.5s ease-in;
}
.image-cover img {
    width: 100%;
    height: auto;
    border-radius: 0.25rem;
    
}
.image-thumbnail {
    width: 4em;
    height: 4em;
    background-color: var(--textColor--);
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid var(--mainColor--);
}
.image-thumbnail:hover {
    transform: scale(1.5 , 1);
    transition: 0.4s 0.1s ease-in;
}
.image-thumbnail img {
    width: 4em;
}


.product-name {
    font-size: 2em;
    font-weight: bold;

}
.product-price , .product-code span:nth-child(1) {
    font-weight: bold;
}
span.code-product {
    color: var(--mainColor--);
    font-weight: bold;
}
span.price-product {
    color: var(--mainColor--);
    font-weight: bold;

}
.features, .overview {
    background-color: var(--textColor--);
    border-radius: 16px;
    padding: 1%;
    /* margin: 0; */
}


.feature {
   
    /* border: 2px solid var(--mainColor--); */
    /* min-width: 2em; */
    padding: 0.25rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0.25rem;
    gap: 4px;
    
   
}
.feature .feature-icon {
    width: 4rem;
    height: 4rem;
    border-radius: 16px;
    background-color: var(--textColor--);
    border: 0.08rem solid var(--mainColor--);
    box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.feature-icon-img {
    width: 1.5rem;
}
.feature .feature-details {
    
    color: var(--mainColor--);
}
.feature .feature-name {
    font-weight: bold;
    color: var(--secondColor--);
}

.btn-check:focus+.btn, .btn:focus {
    outline: 0;
    /* box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25); */
}
.price-before-discount {
    text-decoration: line-through;
}