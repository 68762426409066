
:root {
    --mainColor--: #9933FF;
    --secondColor--: #19191b;
    --thirdColor--: #000000;
    --textColor--: #ffffff;
    --hoverLink--: #916bb8;
}

.section-heading {
    /* background-color: var(--hoverLink--); */
    margin: 2% 0;
    padding: 2rem;

    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* background-color: var(--hoverLink--); */
}

.section-heading .heading {
    font-weight: bold;
    font-size: 3em;
    /* color: #d1d1d1cd; */
    color: var(--mainColor--);
    /* background-color: var(--mainColor--); */
    
}
.heading::after {
    content: "";
    position: absolute;
    top: 10%;
    right: 45%;
    width: 2%;
    height: 0.1rem;
    border-radius: .2rem;
    background-color: #d1d1d1cd;
    transform: rotate(-45deg);

}
.heading::before {
    content: "";
    position: absolute;
    bottom: 20%;
    right: 55%;
    width: 2%;
    height: 0.1rem;
    border-radius: .2rem;
    background-color: #d1d1d1cd;
    transform: rotate(-45deg);

}
.heading-description {
    font-weight: bold;
    color: #767676cd;
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
    .section-heading .heading {
        font-weight: bold;
        font-size: 1.5em;
        /* color: #d1d1d1cd; */
        color: var(--mainColor--);
        /* background-color: var(--mainColor--); */
        
    }
    .heading::after {
        content: "";
        position: absolute;
        top: 20%;
        right: 45%;
        width: 2%;
        height: 0.1rem;
        border-radius: .2rem;
        background-color: #d1d1d1cd;
        transform: rotate(-45deg);
    
    }
    .heading::before {
        content: "";
        position: absolute;
        top: 60%;
        right: 55%;
        width: 2%;
        height: 0.1rem;
        border-radius: .2rem;
        background-color: #d1d1d1cd;
        transform: rotate(-45deg);
    
    }
}