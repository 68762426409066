:root {
    --mainColor--: #9933FF;
    --secondColor--: #19191b;
    --thirdColor--: #000000;
    --textColor--: #ffffff;
    --hoverLink--: #916bb8;
}

.offer-slider-container {
    overflow: hidden;
    width: 100%;
    height: 88vh;
    position: relative;
    background: #fafafa;
}
.image-slider {
    overflow: hidden;
    height: auto;
}
.image-slider img {
    width: 100%;
    height: 100%;
}
.next-slide , .prev-slide {
    position: absolute;
}
.next-slide:hover , .prev-slide:hover {
    transform: scale(1.2);
    transition: 0.8s ease;
    cursor: pointer;
}
.next-slide {
    width: 2rem;
    height: 2rem;
    transform: skew(-8deg);
    /* border-radius: 50%; */
    background-color: var(--mainColor--);
    display: flex;
    justify-content: center;
    align-items: center;
    right: 1%;
    top: 50%;
}
.prev-slide {
    width: 2rem;
    height: 2rem;
    transform: skew(8deg);
    /* border-radius: 50%; */
    background-color: var(--mainColor--);
    display: flex;
    justify-content: center;
    align-items: center;
    left: 1%;
    top: 50%;
}
ion-icon {
    font-size: 1.5rem;
    color: var(--textColor--);
}
.offer-slider-loader {
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.offer_name {
    width: 100%;
    position: absolute;
    bottom: 0rem;
    /* text-align: center; */
    background-color: #6716b7e9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    align-items: center;
    padding: 0.2rem;
}
.offer_name h2 {
    color: var(--textColor--);
    font-size: 2rem;
}
.offer_name p {
    color: var(--textColor--);;
    font-size: 1rem;
}


@media only screen and (min-width: 320px) and (max-width: 480px) {
    .offer-slider-container {
        overflow: hidden;
        width: 100%;
        height: 24vh;
        position: relative;
        background: #fafafa;
    }
    .image-slider {
        overflow: hidden;
        height: auto;
    }
    .offer_name {
        width: 100%;
        position: absolute;
        bottom: 0rem;
        /* text-align: center; */
        background-color: #6716b7e9;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 1rem;
        align-items: center;
        padding: 0.2rem;
    }
    .offer_name h2 {
        color: var(--textColor--);
        font-size: 1rem;
    }
    .offer_name p {
        color: var(--textColor--);;
        font-size: 0.5rem;
    }
    .offer-slider-loader {
        width: 100%;
        height: 20vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .next-slide {
        width: 1rem;
        height: 1rem;
        transform: skew(-8deg);
        /* border-radius: 50%; */
        background-color: var(--mainColor--);
        display: flex;
        justify-content: center;
        align-items: center;
        right: 1%;
        top: 50%;
    }
    .prev-slide {
        width: 1rem;
        height: 1rem;
        transform: skew(8deg);
        /* border-radius: 50%; */
        background-color: var(--mainColor--);
        display: flex;
        justify-content: center;
        align-items: center;
        left: 1%;
        top: 50%;
    }
    ion-icon {
        font-size: 1.5rem;
        color: var(--textColor--);
    }
}

@media only screen and (min-width: 480px) and (max-width: 637px) {
    .offer-slider-container {
        overflow: hidden;
        width: 100%;
        height: 38vh;
        position: relative;
        background: #fafafa;
    }

}
@media only screen and (min-width: 640px) and (max-width: 950px) {

    .offer-slider-container {
        overflow: hidden;
        width: 100%;
        height: 45vh;
        position: relative;
        background: #fafafa;
    }
    .image-slider {
        overflow: hidden;
        height: auto;
    }
    .offer_name {
        width: 100%;
        position: absolute;
        bottom: 0rem;
        /* text-align: center; */
        background-color: #6716b7e9;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 1rem;
        align-items: center;
        padding: 0.2rem;
    }
}


@media only screen and (min-width: 951px) and (max-width: 1280px) {

    .offer-slider-container {
        overflow: hidden;
        width: 100%;
        height: 60vh;
        position: relative;
        background: #fafafa;
    }
    .image-slider {
        overflow: hidden;
        height: auto;
    }
    .offer_name {
        width: 100%;
        position: absolute;
        bottom: 0rem;
        /* text-align: center; */
        background-color: #6716b7e9;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 1rem;
        align-items: center;
        padding: 0.2rem;
    }
}

