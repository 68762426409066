:root {
  --mainColor--: #9933FF;
  --secondColor--: #19191b;
  --thirdColor--: #000000;
  --textColor--: #ffffff;
  --hoverLink--: #916bb8;
}

.service-box {
  padding: 0;
  margin: 0;
  position: relative;
  /* width: 100%; */
  width: 18rem;
  min-height: 18rem;
  background-color: #eee;
  border: solid 2px var(--mainColor--);
  /* border-radius: 16px; */
  border-radius: 1rem;
}

.service-badge {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  padding: 0.25rem 1rem;
  background-color: var(--hoverLink--);
  color: var(--textColor--);
  border-radius: 0.5rem;
}

/* Make the image to responsive */
.image {
  width: 100%;
  height: 100%;
  border-radius: 1rem;
}

/* The overlay effect (full height and width) - lays on top of the container and over the image */
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease-in;
  background-color: rgba(12, 12, 12, 0.878);
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  gap: 4%;
  border-radius: 1rem;
  border-bottom: 0.5rem solid var(--mainColor--);
}

.overlay .service-title {
  color: var(--mainColor--);
  font-weight: bold;
  margin-top: 1rem;
  font-size: 24px;
}

.overlay .service-warranty {
  color: var(--textColor--);
  font-weight: bold;
  margin: 0;
  padding-right: 12%;
}

.overlay button {
  max-width: 150px;
  background-color: var(--textColor--);
  font-weight: bold;
  border: transparent;
  margin-right: 12%;
  font-size: 12px;
}

.overlay button:hover {
  background-color: var(--mainColor--);
  color: var(--textColor--);
  font-weight: bold;
}

/* When you mouse over the container, fade in the overlay icon*/
.service-box:hover .overlay {
  cursor: pointer;
  opacity: 1;
}

/* The icon inside the overlay is positioned in the middle vertically and horizontally */
.icon {
  color: white;
  font-size: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

/* When you move the mouse over the icon, change color */
.fa-user:hover {
  color: #eee;
}

.service-heading {

  margin: 0;
  color: var(--textColor--);
  background-color: var(--mainColor--);
  font-weight: bold;
}

.service-box:hover .service-heading {
  background-color: var(--mainColor--);
  transition: 0.4s 0.1 ease-in-out;
}

.service-box:hover {
  
    transform: scale(1.1);
    margin: 0 2px;
    transition: 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}