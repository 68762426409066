:root {
    --mainColor--: #9933FF;
    --secondColor--: #19191b;
    --thirdColor--: #000000;
    --textColor--: #ffffff;
    --hoverLink--: #916bb8;
    --animate-duration: 2000ms;
    --animate-delay: 0.1s;
}

.nav {
    position: sticky;
    top: 0;
    z-index: 10000000000000000000;
    background-color: var(--textColor--);
    border: 2px solid;
    border-bottom-color: var(--mainColor--);
    border-right-color: transparent;
    border-top-color: transparent;
    border-left-color: transparent;
    padding: 0.6rem 0;

}
.logo {
    animation: bounce; /* referring directly to the animation's @keyframe declaration */
    animation-duration: var(--animate-duration); /* don't forget to set a duration! */
  }
.nav a {
    color: var(--secondColor--);
    font-weight: bold;
}

.nav a:hover {
    color: var(--hoverLink--);
    transform: scale(0.9);
    transition: 0.4s;
}
/* 
.navbar-nav li.nav-item a.nav-link.active {
    color: var(--mainColor--);
    font-weight: bold;
} */

.nav-item.dropdown a.nav-link.dropdown-toggle.show {
    color: var(--mainColor--);
}

.dropdown-menu li a {
    color: var(--hoverLink--);
}

.dropdown-menu li a:hover {
    background-color: var(--mainColor--);
    color: var(--secondColor--);
}


.navbar-toggler {
    padding: .25rem .75rem;
    font-size: 1.25rem;
    line-height: 1;
    /* background-color: var(--secondColor--); */
    /* border: 1px solid var(--secondColor--); */
    /* border: 1px solid transparent; */
    border-radius: .25rem;
    transition: box-shadow .15s ease-in-out;
} 

.navbar-toggler-icon {
    display: inline-block;
    color: var(--hoverLink--);
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
}

a.dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    /* vertical-align: .255em; */
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
}

.collapsed {
    /* color: var(--mainColor--); */
    font-weight: bold;
  
}
.navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 .10rem var(--mainColor--);
    /* transform: rotate(90deg); */
    transition: box-shadow 0.4s ease;
}

.dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: 0;
    content: "";
    margin-right: 0.25rem;
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
    border-radius: 0.2rem;
}

.nav-item a.active {
    background-color: var(--mainColor--);
    border-radius: 0.25rem;
    color: var(--textColor--);
    font-weight: bold;
}

