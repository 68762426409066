
.container-loader {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader {
    width: 25%;
    /* height: 100vh; */
}