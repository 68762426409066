:root {
    --mainColor--: #9933FF;
    --secondColor--: #19191b;
    --thirdColor--: #000000;
    --textColor--: #ffffff;
    --hoverLink--: #916bb8;
}


div.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

}

.wrapper-container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: start;
}
.layout {
    width: 12rem;
    height: 32rem;
    border-radius: 0.25rem;
    background-size: cover;
    cursor: pointer;
    overflow: hidden;
    margin: 0 .8rem;
    display: flex;
    justify-items: center;
    align-items:flex-start;
    transition: .6s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.8);
    
    
}
.layout > .layout-row > .layout-icon {
    background-color: var(--mainColor--);
    color: var(--textColor--);
    border-radius: 0.25rem;
    /* min-width: 8rem; */
    max-width: 14rem;
    padding: 0.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.5rem 1rem;
}
.layout > .layout-row > .layout-description {
   display: flex;
   justify-content: center;
   flex-direction: column;
 
   overflow: hidden;

   /* width: 520px; */
   padding-right: 1rem;
   padding-bottom: 1rem;
   opacity: 0;
   transform: translateY(30px);
   transition-delay: .3s;
   transition: all .3s ease;
}
.layout-description p {
    font-weight: normal;
    color: var(--textColor--);
    padding-top: 5px;
    font-size: 0.8rem;
    font-weight: bold;
    margin: 0;
}
.layout-description h4 {
    /* font-size: 12px; */
    /* text-transform: uppercase; */
    color: var(--textColor--);
    margin: 0;
}
.wrapper-container input {
    display: none;
}
.wrapper-container input:checked + label {
    width: 48rem;
    border: 0.25em solid var(--mainColor--);

}
.wrapper-container input:checked + label .layout-description{
    opacity: 1 !important;
    transform: translateY(0) !important;
}

.layout[for="c1"] {
    background-image: url('../images/img5.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    
    
}
.layout[for="c2"] {
    background-image: url('../images/img3.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    
    
}
.layout[for="c3"] {
    background-image: url('../images/img6.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    
    
}


.payments-avaliable-ways {
    padding: 2rem;
    background-color: var(--textColor--);
    border: 0.1rem solid transparent;
    border-right-color: var(--mainColor--);
    display: flex;
    border-radius: 0.25rem;
    
    

}
.third-party-payments {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}
.third-party-payments h2 {
    font-weight: bold;
    font-size: 1rem;
    color: var(--mainColor--);
    margin: 0;
    
}
.third-party-payments .payments-ways {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 0.8rem;
    

}

.third-party-payments .payments-ways img {
    width: 4rem;
    height: 4rem;
}
.third-party-payments .payments-ways img:hover {
    transform: scale(1.1);
    margin: 0 1rem;
    transition: 0.4s ease;
}

.book-confirm-btn {
    outline: none;
    padding: 0.8rem;
    border-radius: 0.25rem;
    background-color: var(--secondColor--);
    border: transparent;
    font-weight: bold;
    color: var(--textColor--);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.8rem;
}
.book-confirm-btn:hover {
  
    background-color: var(--mainColor--);
    transition: 0.4s ease;
    color: var(--textColor--);
}
.confirm-btn-icon {
    width: 1.5rem;
}

.table {
    --bs-table-bg: #ffffff;
    --bs-table-accent-bg: transparent;
    --bs-table-striped-color: #19191b;
    --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
    --bs-table-active-color: #212529;
    --bs-table-active-bg: rgba(0, 0, 0, 0.1);
    --bs-table-hover-color: #212529;
    --bs-table-hover-bg: #d3d3d388;
    width: 100%;
    margin-bottom: 1rem;
    color: var(--mainColor--);
    vertical-align: middle;
    border-color: #2b2b2b;
    border-radius: 0.5rem;
}

/* media screens  */
@media only screen and (min-width: 320px) and (max-width: 850px) {
    .layout {
        width: 18rem;
        height: 12rem;
        border-radius: 0.25rem;
        background-size: cover;
        cursor: pointer;
        overflow: hidden;
        /* margin: 0 10px; */
        display: flex;
        align-items: flex-end;
        transition: .6s cubic-bezier(0.445, 0.05, 0.55, 0.95);
        box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.8);
        
        
    }
    .wrapper-container input:checked + label {
        width: 18rem;
        height: 24rem;
    
    }
    .third-party-payments .payments-ways img {
        width: 8rem;
    }
    .wrapper-container input:checked + label .layout-description{
        opacity: 1 !important;
        padding-bottom: 1rem;
        
    }
    
    .wrapper-container {
        display: flex;
        gap: 2rem;
        flex-direction: column;
        justify-content: start;
    }

    .layout > .layout-row > .layout-icon {
        background-color: var(--mainColor--);
        color: var(--textColor--);
        border-radius: 0.25rem;
        /* min-width: 8rem; */
        max-width: 14rem;
        padding: 0.5rem 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0.5rem 1rem;
    }
    .layout > .layout-row > .layout-description {
       display: flex;
       justify-content: center;
       flex-direction: column;
       color: rgb(255, 255, 255);
       overflow: hidden;

       /* width: 520px; */
       padding-right: 1rem;
       padding-bottom: 2rem;
       opacity: 0;
       transform: translateY(30px);
       transition-delay: .3s;
       transition: all .3s ease;
    }
}

@media only screen and (min-width: 850px) and (max-width: 1211px) { 

    .layout {
        width: 10rem;
        height: 32rem;
        border-radius: 0.25rem;
        background-size: cover;
        cursor: pointer;
        overflow: hidden;
        margin: 0 10px;
        display: flex;
        align-items: flex-end;
        transition: .6s cubic-bezier(0.445, 0.05, 0.55, 0.95);
        box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.8);
        
        
    }
    .wrapper-container input:checked + label {
        width: 24rem;
        height: 32rem;
    
    }
    .third-party-payments .payments-ways img {
        width: 8rem;
    }
    .wrapper-container input:checked + label .layout-description{
        opacity: 1 !important;
        padding-bottom: 1rem;
        
    }
    
    .wrapper-container {
        display: flex;
        gap: 2rem;
        flex-direction: row;
        justify-content: start;
    }

    .layout > .layout-row > .layout-icon {
        background-color: var(--mainColor--);
        color: var(--textColor--);
        border-radius: 0.25rem;
        /* min-width: 8rem; */
        max-width: 14rem;
        padding: 0.25rem 0.10rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 1rem;
    }
    .layout > .layout-row > .layout-description {
       display: flex;
       justify-content: center;
       flex-direction: column;
       color: rgb(255, 255, 255);
       overflow: hidden;

       /* width: 520px; */
       padding-right: 1rem;
       padding-bottom: 2rem;
       opacity: 0;
       transform: translateY(30px);
       transition-delay: .3s;
       transition: all .3s ease;
    }
}


.thumbnail {
    opacity: 0.8;
    width: 100%;
}