:root {
    --mainColor--: #9933FF;
    --secondColor--: #19191b;
    --thirdColor--: #000000;
    --textColor--: #ffffff;
    --hoverLink--: #916bb8;
}
.box {
    /* min-width: 300px;
    max-width: 400px; */
    /* height: 180px; */
    /* background: var(--mainColor--); */
    border-radius: 0.2rem;
    display: flex;
    flex-direction: column;
    padding: 0;
    /* justify-content: space-between; */

}

.box-icon img {
    width: 5rem;
    height: 5rem;
}
.box-icon img:hover {
    cursor: pointer;
    transform: scale(1.2,1.2);
    transition: 0.5s 0.1s ease-in-out;
}
.box-icon-title {
    /* background-color: #19191b; */
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
}

.box-title {
    
    font-weight: bold;
    color: var(--textColor--);
}
.box-title p {
    font-size: 1.2rem;
    color: var(--mainColor--);
    /* background-color: var(--secondColor--); */
}
.box-description p {
    width: 100%;
    font-size: 1rem;
    text-wrap: wrap;
    display: flex;
    justify-content: center;
    align-items: center;
    
    /* background-color: var(--hoverLink--); */
}

.box-description {
    /* padding: 0px 4px 0px 4px; */
    background-color: var(--mainColor--);
    border-radius: 0.2rem;
    padding: 0.2rem;
}