:root {
    --mainColor--: #9933FF;
    --secondColor--: #19191b;
    --thirdColor--: #000000;
    --textColor--: #ffffff;
    --hoverLink--: #916bb8;
}

.footer {
    /* width: 100%; */
    /* margin-top: 2%; */
    background-color: var(--textColor--);
    color: var(--textColor--);
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    border: 2px solid;
    border-top-color: var(--mainColor--);
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
    

}



.card-body {
    width: 100%;
    margin: 1% 0 1% 0;
   
}
   
.card-footer {
    width: 100%;
    background-color: var(--mainColor--);
    color: var(--textColor--);
    /* vertical-align: middle; */
    /* direction: ltr; */
}
.following-payments {
    margin: 0.25rem 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
   
    align-items: flex-end;
    /* background-color: #916bb8; */
}
.links, .payment {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: center;
    padding: 0.50rem 0;
    gap: 2%;
}
.following, .payments {
    /* width: 100%; */
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center;
    align-content: center; */
    /* min-width: 32rem; */
    /* border: 2px solid var(--mainColor--); */
    /* background-color: var(--hoverLink--); */
    border-radius: 0.2rem;
    
}
.following-title , .payments-title {
    font-size: 1rem;
    padding: 0;
    color: var(--secondColor--);
    font-weight: bold;
    margin: 0;
    background-color: var(--mainColor--);
    color: var(--textColor--);
    border-radius: 0.2rem;
    padding: 0.25rem 1rem;
}

.payment-icon {
    width: 3rem;
    position: relative;
}
.payment-icon:hover {
    transform: scale(1.1,1.1);
    transition: 0.4s ease-in;
    cursor: pointer;
}
.privacy-policy {
    text-decoration: none;
}


