:root {
    --mainColor--: #9933FF;
    --secondColor--: #19191b;
    --thirdColor--: #000000;
    --textColor--: #ffffff;
    --hoverLink--: #916bb8;
}

.search-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    align-items: center;
    gap: 0.8rem;
}
.search-input {
    min-width: 24em;
    outline: none;
    padding-right: 1em;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    padding: 0.30em 1em;
}
.search-input:focus {
    border: 1px solid var(--mainColor--);
    transform: scale(1.1,1.1);
    transition: 0.3s  ease;
    margin-left: 1%;
}
.btn-sorting {
    padding: 0.25em 0.5em;
}

.btn-check:focus+.btn, .btn:focus {

    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(13, 109, 253, 0);
}

@media only screen and (min-width: 320px) and (max-width: 580px) {
    .search-input {
        min-width: 14em;
        outline: none;
        padding-right: 1em;
        border: 1px solid transparent;
        border-radius: 0.25rem;
    }
   
   
}

.search-form button     {
    outline: none;
    border: 1px solid var(--mainColor--);
   
    padding: 4px 16px;
    border-radius: 8px;
    background-color: transparent;
    font-weight: bold;
    color: var(--mainColor--);
}
.btn-sorting {
    /* padding: 4px 8px; */
    border: 1px solid var(--mainColor--);
    background-color: var(--mainColor--);
    color: var(--textColor--);
    border-radius: 8px;
}
.search-form button:hover , .btn-sorting:hover {
    background-color: var(--mainColor--);
    transition: 0.4s 0.1s ease-in-out;
    color: var(--textColor--);
}
.products-list {
    gap: 16px;
}
