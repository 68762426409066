.logo {
    width: 10em;
    height: 2em;
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
    .logo {
        width: 100px;
        height: 50px;
    }
}