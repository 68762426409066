:root {
    --mainColor--: #9933FF;
    --secondColor--: #19191b;
    --thirdColor--: #000000;
    --textColor--: #ffffff;
    --hoverLink--: #916bb8;
}

.gallery-service {
    column-count: 4;
    -webkit-column-count: 4;
    -moz-column-count: 4;
    /* column-width: 10%;
    -webkit-column-width: 10%;
    -moz-column-width: 10%; */
    padding: 0 18px;
    margin: 1rem 0;
}
.image-gallery img {
    width: 100%;
    height: 24rem;
    border-radius: 1rem;
}
.gallery-service .image-gallery {
    transition: all 350ms ease;
    cursor: pointer;
    margin-bottom: 8px;
    border-radius: 1rem;
}
.gallery-service .image-gallery:hover {
    filter: opacity(0.8);
    transition: all 350ms ease;
}
