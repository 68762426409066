:root {
    --mainColor--: #9933FF;
    --secondColor--: #19191b;
    --thirdColor--: #000000;
    --textColor--: #ffffff;
    --hoverLink--: #916bb8;
}
.book-review-componnet {
    width: 24rem;
    /* height: 8rem; */
    background-color: var(--secondColor--);
    border-radius: 0.25rem;
    
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.75rem;
    /* align-content: space-between; */
    padding: 0.5rem;
    font-size: 16px;
}
.book-review-componnet .review-heading {
    width: 100%;
    margin-top: 0.25rem;
    /* background-color: #ffffff; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* align-content: space-between; */
    padding: 0px 1rem;
    color: var(--textColor--);
}
.review-heading img.book-review-icon {
    width: 1.5rem;
}



.review-value {
    color: var(--mainColor--);
    /* justify-self: center; */
}