
:root {
    --mainColor--: #9933FF;
    --secondColor--: #19191b;
    --thirdColor--: #000000;
    --textColor--: #ffffff;
    --hoverLink--: #916bb8;
    --animate-duration: 2000ms;
    --animate-delay: 0.1s;
}
.carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    height: 40em;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: transform .6s ease-in-out;
}

.carousel-item .slider-image {
    width: 100%;
    height: auto;
    height: auto;

}

@media only screen and (min-width: 320px) and (max-width: 480px) {
    .carousel-item {
        position: relative;
        display: none;
        float: left;
        width: 100%;
        height: auto;
        margin-right: -100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        transition: transform .6s ease-in-out;
    }
}

.slide-heading {
    color: var(--mainColor--);
    font-weight: bold;
}

@media Only Screen and (min-width: 425px) {
    .d-md-block {
        display: block !important;
    }
}

.carousel-control-next-icon, .carousel-control-prev-icon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%;
    background-color: var(--mainColor--);
    border-radius: 50%;
}

.carousel-control-next-icon {
    background-image: url('../images/arrowRight.png');
}
.carousel-control-prev-icon {
    background-image: url('../images/arrowLeft.png');
}