
:root {
  --mainColor--: #9933FF;
  --secondColor--: #19191b;
  --thirdColor--: #000000;
  --textColor--: #ffffff;
  --hoverLink--: #916bb8;
}
@import url('https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&display=swap');
body {
  margin: 0;
  direction: rtl;
  font-family: "Almarai", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: var(--textColor--); */
  background-color: #fafafa;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 0.4rem;
  
}

::-webkit-scrollbar-track {
  background-color: var(--thirdColor--);

}
::-webkit-scrollbar-thumb {
  background-color: var(--mainColor--);
  border-radius: 8rem;
  
}
