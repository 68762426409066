:root {
    --mainColor--: #9933FF;
    --secondColor--: #19191b;
    --thirdColor--: #000000;
    --textColor--: #ffffff;
    --hoverLink--: #916bb8;
}

input {
    direction: rtl;

}

label {
    color: var(--hoverLink--);
    font-weight: bold;
}
input:focus {
    color: var(--mainColor--);
}
.form-floating>label {
    right: 0;
    top: 0;
}

